<template>
  <div class="container">
    <div class="row text-center">
      <div class="col-12 text-center">
        <div class="pd-auto">
          <img class="img-fluid main-banner"
               src="../../assets/images/new_banner2.jpg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name : 'UserBanner',
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/components/shared/user-banner";
</style>
